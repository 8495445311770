<template>
  <div id="correlation-list">
    <Loader v-if="isLoading" />
    <div v-show="!isLoading">
      <div style="text-align: right !important">
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus-circle"
          @click="onCreateClick"
          >{{ $l.get("dictionary.create-button") }}</vs-button
        >
      </div>
      <vs-table
        :sst="true"
        @search="handleSearch"
        @sort="handleSort"
        search
        v-if="data"
        :data="data.correlationsPage.results"
      >
        <template slot="header">
          <h3>{{ $l.get("correlation-list.table-header") }}</h3>
        </template>
        <template slot="thead">
          <vs-th sort-key="name">{{
            $l.get("correlation-list.table-name")
          }}</vs-th>
          <vs-th>{{ $l.get("dictionary.view") }}</vs-th>
          <vs-th>{{ $l.get("dictionary.edit") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.name }}</vs-td>
            <vs-td>
              <vs-button
                color="primary"
                type="border"
                icon="assessment"
                @click="onCorrelationViewClick(tr)"
              ></vs-button>
            </vs-td>
            <vs-td>
              <vs-button
                color="primary"
                type="border"
                icon="edit"
                @click="onCorrelationEditClick(tr)"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div style="margin-top: 20px; text-align: center">
        <vs-pagination
          :total="Math.ceil(data.correlationsPage.count / 25)"
          v-model="page"
          v-if="data"
        ></vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SelectCorrelationViewInteractor from "@/business/correlation-list/select-correlation-view";
import SelectCreateInteractor from "@/business/correlation-list/select-create";
import SelectCorrelationEditInteractor from "@/business/correlation-list/select-correlation-edit";
import ChangeFilterInteractor from "@/business/correlation-list/change-filter";
import InitCorrelationListInteractor from "@/business/correlation-list/init-correlation-list-screen";
import CorrelationListScreenController from "@/adapters/controllers/screen-correlation-list";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "correlation-list",
  data() {
    return {
      controller: null,
      interactors: {
        selectCorrelationView: null,
        selectCreate: null,
        selectCorrelationEdit: null,
        changeFilter: null,
        initCorrelationListScreen: null
      },
      data: null,
      isLoading: false,
      page: 0,
      search: "",
      sort: "",
      searchTimer: null
    };
  },
  components: { Loader },
  beforeMount() {
    this.controller = this.$injector.get(CorrelationListScreenController);

    //{ INTERACTORS
    this.interactors.initCorrelationListScreen = this.$injector.get(
      InitCorrelationListInteractor
    );
    this.interactors.changeFilter = this.$injector.get(ChangeFilterInteractor);
    this.interactors.selectCorrelationEdit = this.$injector.get(
      SelectCorrelationEditInteractor
    );
    this.interactors.selectCreate = this.$injector.get(SelectCreateInteractor);
    this.interactors.selectCorrelationView = this.$injector.get(
      SelectCorrelationViewInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initCorrelationListScreen.handle();
  },
  watch: {
    page: function() {
      this.interactors.changeFilter.handle(this.page, this.search, this.sort);
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    handleSearch(searching) {
      this.search = searching;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      const _ = this;
      this.searchTimer = setTimeout(() => {
        _.searchTimer = null;
        if (_.page != 1) {
          _.page = 1; // watch force update
        } else {
          _.interactors.changeFilter.handle(_.page, _.search, _.sort);
        }
        //
      }, 500);
    },
    handleSort(key, active) {
      if (active) {
        const prefix = active == "desc" ? "-" : "";
        this.sort = `${prefix}${key}`;
      } else {
        this.sort = "";
      }
      this.interactors.changeFilter.handle(this.page, this.search, this.sort);
    },
    onCorrelationViewClick(data) {
      this.interactors.selectCorrelationView.handle(data);
    },
    onCorrelationEditClick(data) {
      this.interactors.selectCorrelationEdit.handle(data);
    },
    onCreateClick() {
      this.interactors.selectCreate.handle();
    }
  }
};
</script>

<style lang="scss"></style>
